import {
  SET_CURRENCY,
  SET_CURRENCY_RATES,
  SYMBOLS,
  RatesInterface,
  CurrencyAction,
  CurrencyRatesAction
} from "./types";

export const setCurrency = (currency: string): CurrencyAction => {
  return {
    type: SET_CURRENCY,
    payload: { currency }
  };
};

export const setCurrencyRates = (
  rates: RatesInterface
): CurrencyRatesAction => {
  const selectedRates: RatesInterface = {
    EUR: rates.EUR,
    CAD: rates.CAD,
    USD: rates.USD,
    MXN: rates.MXN
  };

  return {
    type: SET_CURRENCY_RATES,
    payload: { rates: selectedRates }
  };
};

/**
 * Fetch resource from server
 * @param {String} entity entity to fetch from server
 * @param {Object} params request parameters
 */
export const changeCurrency = (currency: string) => (dispatch: Function) => {
  if (currency !== undefined && Object.keys(SYMBOLS).includes(currency)) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/v1/rates?currency=${currency}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        localStorage.setItem("currency", currency);
        dispatch(setCurrencyRates(data));
        return dispatch(setCurrency(currency));
      })
      .catch(() => dispatch(setCurrencyRates({})));
  }
};
