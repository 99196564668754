import { useMemo } from "react";
import LocalizedStrings from "react-localization";

import { useSelector } from "react-redux";
import { getLocale } from "modules/settings/selectors";

import defaultTranslations from "translations";

export default (translations: any = defaultTranslations, currentLocale?: string) => {
  const locale = useSelector(getLocale);

  return useMemo(() => {
    const lang = new LocalizedStrings(translations);
    lang.setLanguage(currentLocale || locale || "en");
    return lang;
  }, [currentLocale, locale, translations]);
};
