export default {
  en: {
    siteTitle: "Cottages, Vacation Rentals & Unique Homes in Nature",
    titleTemplate: "%s | WeChalet",
    bedTypes: {
      single: "Single",
      double: "Double",
      queen: "Queen",
      king: "King",
      bunk_bed: "Bunk Bed",
      air_mattress: "Air Mattress",
      crib: "Crib",
      toddler_bed: "Toddler's Bed",
      hammock: "Hammock",
      water_bed: "Water Bed",
      sofa_bed: "Sofa Bed",
      murphy_bed: "Murphy Bed",
      couch: "Couch",
      floor_mattress: "Floor Mattress"
    },
    pluralBedTypes: {
      single: "Singles",
      double: "Doubles",
      queen: "Queens",
      king: "Kings",
      bunk_bed: "Bunk Beds",
      air_mattress: "Air Mattresses",
      crib: "Cribs",
      toddler_bed: "Toddler's Beds",
      hammock: "Hammocks",
      water_bed: "Water Beds",
      sofa_bed: "Sofa Bed",
      murphy_bed: "Murphy Beds",
      couch: "Couches",
      floor_mattress: "Floor Mattresses"
    },
    propertyTypes: {
      home: "Home",
      "b-and-b": "B & B",
      apartment: "Apartment",
      cabin: "Cabin",
      castle: "Castle",
      cave: "Cave",
      chalet: "Chalet",
      condo: "Condo",
      cottage: "Cottage",
      "country-home": "Country Home",
      dorm: "Dorm",
      "farm-home": "Farm Home",
      "guest-suite": "Guest Suite",
      guesthouse: "Guesthouse",
      hostel: "Hostel",
      hut: "Hut",
      inn: "Inn",
      island: "Island",
      loft: "Loft",
      "nature-lodge": "Nature lodge",
      other: "Other",
      shelter: "Shelter",
      timeshare: "Timeshare",
      "tree-house": "Tree house",
      "vacation-home": "Vacation home",
      villa: "Villa",
      "beach-house": "Beach house",
      boat: "Boat",
      "boutique-hotel": "Boutique hotel",
      camper: "Camper/RV",
      tent: "Tent",
      townhouse: "Townhouse",
      yurt: "Yurt",
      hotel: "Hotel"
    },
    rentTypes: {
      entire_home: "Entire Home",
      private_room: "Private Room",
      shared_room: "Shared Room"
    },
    amenityGroups: {
      general: "Indoor Features",
      family_features: "Family Features",
      outdoor: "Outdoor Features",
      sports_adventures_nearby: "Sports & Adventures Nearby",
      kitchen: "Kitchen",
      suitable_for_events: "Suitable for Events",
      accessibility: "Accessibility",
      services: "Services"
    },
    amenityNames: {
      "air-conditioning": "Air conditioning",
      "bed-linens": "Bed linens",
      "cable-tv": "Cable TV",
      dryer: "Dryer",
      "dvd-blue-ray": "DVD / Blue Ray",
      "carbon-monoxide-detector": "Carbon monoxide detector",
      "smoke-detector": "Smoke detector",
      "extra-pillows-and-blankets": "Extra pillows and blankets",
      "firewood-included": "Firewood included",
      "hair-dryer": "Hair dryer",
      "hangers-in-closets": "Hangers in closets",
      "heated-floors": "Heated floors",
      heating: "Heating",
      "home-essentials": "Home essentials",
      "hot-water": "Hot water",
      "indoor-fireplace": "Indoor fireplace",
      iron: "Iron",
      kitchen: "Kitchen",
      "computer-workspace": "Computer workspace",
      "potable-water": "Potable water",
      "shampoo-soap": "Shampoo & soap",
      "toilet-paper": "Toilet paper",
      towels: "Towels",
      tv: "TV",
      washer: "Washer",
      "wireless-internet": "Wireless Internet",
      "baby-bath": "Baby bath",
      "baby-monitor": "Baby monitor",
      "babysitter-referrals": "Babysitter referrals",
      bathtub: "Bathtub",
      "changing-table": "Changing table",
      "children-s-books-and-toys": "Children’s books and toys",
      "children-s-dinnerware": "Children’s dinnerware",
      crib: "Crib",
      "fireplace-guards": "Fireplace guards",
      "foosball-table": "Foosball table",
      "game-console": "Game console",
      "high-chair": "High chair",
      "outlet-covers": "Outlet covers",
      "ping-pong-table": "Ping pong table",
      "pool-table": "Pool table",
      "room-darkening-shades": "Room-darkening shades",
      "stair-gates": "Stair gates",
      "table-corner-guards": "Table corner guards",
      "window-guards": "Window guards",
      "coffee-maker": "Coffee maker",
      "cooking-basics": "Cooking basics",
      "dishes-and-silverware": "Dishes and silverware",
      dishwasher: "Dishwasher",
      microwave: "Microwave",
      oven: "Oven",
      "pots-and-pans": "Pots and pans",
      refrigerator: "Refrigerator",
      stove: "Stove",
      "access-to-water": "Access to water",
      "bbq-grill": "BBQ / Grill",
      "beach-chairs": "Beach chairs",
      beachfront: "Beachfront",
      "by-a-river": "By a river",
      dock: "Dock",
      "fire-pit": "Fire pit",
      "garden-or-backyard": "Garden or backyard",
      hamak: "Hamak",
      "hot-tub-spa": "Hot tub / Spa",
      lakefront: "Lakefront",
      "life-jackets": "Life jackets",
      "patio-furniture": "Patio furniture",
      "patio-or-balcony": "Patio or balcony",
      pool: "Pool",
      "sandy-beach": "Sandy beach",
      sauna: "Sauna",
      "ski-in-ski-out": "Ski in / ski out",
      "volleyball-net": "Volleyball net",
      "alpine-skiing": "Alpine Skiing",
      "atv-trail": "ATV trail",
      basketball: "Basketball",
      boating: "Boating",
      parasailing: "Parasailing",
      "road-biking": "Road biking",
      "mountain-biking": "Mountain Biking",
      kayaking: "Kayaking",
      "jet-skiing": "Jet skiing",
      "ice-climbing": "Ice Climbing",
      "horseback-riding": "Horseback riding",
      hockey: "Hockey",
      hiking: "Hiking",
      golf: "Golf",
      fishing: "Fishing",
      "deepsea-fishing": "Deep Sea fishing",
      "cross-country-skiing": "Cross-country skiing",
      caving: "Caving",
      canoeing: "Canoeing",
      "rock-climbing": "Rock Climbing",
      rollerblading: "Rollerblading",
      running: "Running",
      sailing: "Sailing",
      "scuba-diving": "Scuba diving",
      skating: "Skating",
      skydiving: "Skydiving",
      snorkeling: "Snorkeling",
      snowboarding: "Snowboarding",
      "snowmobile-trail": "Snowmobile trail",
      snowshoeing: "Snowshoeing",
      swimming: "Swimming",
      windsurfing: "Windsurfing",
      tennis: "Tennis",
      "tree-climbing": "Tree Climbing",
      volleyball: "Volleyball",
      "water-skiing": "Water skiing",
      "water-sliding": "Water sliding",
      "whitewater-rafting": "Whitewater rafting",
      "elevator-in-the-building": "Elevator in the building",
      "private-entrance": "Private entrance",
      "single-level-home": "Single level home",
      "wheelchair-accessible": "Wheelchair accessible",
      "breakfast-included": "Breakfast included",
      "ev-charger": "EV charger",
      "free-gym-fitness-centre": "Free gym / Fitness centre",
      "free-parking-on-premises": "Free parking on premises",
      "guided-tours": "Guided tours",
      "maid-service-included": "Maid service included",
      "restaurant-on-site": "Restaurant on site",
      "spa-services": "Spa services",
      "tea-coffee-included": "Tea & coffee included",
      "art-show": "Art show",
      "bachelor-ette-party": "Bachelor(ette) party",
      "birthday-party": "Birthday party",
      conference: "Conference",
      "corporate-meeting": "Corporate meeting",
      "executive-retreat": "Executive retreat",
      "friends-family-meeting": "Friends & Family meeting",
      "photo-film-shoot": "Photo & Film Shoot",
      "product-launch": "Product launch",
      "seminar-networking": "Seminar & Networking",
      "sport-team-training": "Sport team training",
      wedding: "Wedding"
    },
    houseRules: {
      name: "House Rules",
      allow_infants: "Infants under 2 years old are welcome",
      not_allow_infants:
        "This place is not suited for infants under 2 years old",
      allow_children: "Children are welcome (2 to 12 years old)",
      not_allow_children:
        "This place is not suited for children (2 to 12 years old)",
      allow_smoking: "Smoking inside is allowed",
      not_allow_smoking: "Smoking inside is not allowed",
      allow_pets: "Pets are allowed",
      not_allow_pets: "Pets are not allowed",
      allow_parties: "Parties and events are allowed",
      not_allow_parties: "Parties and events are not allowed"
    },
    guestExpectationsRules: {
      "must-climb-stairs": "Must climb stairs",
      "potential-for-noise": "Potential for noise",
      "pets-live-on-property": "Pets live on property",
      "no-parking-on-property": "No parking on property",
      "some-spaces-are-shared": "Some spaces are shared",
      "amenity-limitations": "Amenity limitations",
      "cell-phone-signal-weak":
        "Cell phone signal can be weak around this property",
      "awd-vehicles-preferable":
        "Access with AWD vehicles is preferable in winter",
      "water-safe-to-drink": "Water is not safe to drink"
    },
    actions: {
      save: "Save",
      save_next: "Next Step",
      next: "Next",
      cancel: "Cancel",
      publish: "Publish",
      pause: "Pause",
      delete: "Delete",
      back: "Back",
      viewListing: "View Listing"
    },
    add_property: "Add Property",
    edit_property: "Edit Property",
    space: "Space",
    location: "Location",
    amenities: "Amenities",
    photos: "Photos",
    description: "Description",
    booking_settings: "Booking Settings",
    pricing: "Pricing",
    calendar: "Calendar",
    guest_material: "Guest Material",
    status: "Status",
    discount_limit: "The maximum rebate is 99%.",
    pricing_limit: "Please, put a maximum of 6 digits.",
    taxNames: {
      TPS: "GST",
      TVH: "HST",
      TVP: "PST",
      TVQ: "QST",
      GST: "GST",
      HST: "HST",
      PST: "PST",
      QST: "QST"
    },
    fraudText: "We detect frauds",
    fraudTextLine2: "Your identity will be verified"
  },
  fr: {
    siteTitle: "Maisons, Condos & Chalets Uniques en Nature",
    titleTemplate: "%s | WeChalet",
    bedTypes: {
      single: "Simple",
      double: "Double",
      queen: "Queen",
      king: "King",
      bunk_bed: "Lit superposé",
      air_mattress: "Matelas gonflable",
      crib: "Bassinette",
      toddler_bed: "Lit pour bébé",
      hammock: "Hamac",
      water_bed: "Lit d'eau",
      sofa_bed: "Divan-lit",
      murphy_bed: "Lit Escamotable",
      couch: "Divan",
      floor_mattress: "Matelas de sol"
    },
    pluralBedTypes: {
      single: "Simples",
      double: "Doubles",
      queen: "Queens",
      king: "Kings",
      bunk_bed: "Lits superposés",
      air_mattress: "Matelas gonflables",
      crib: "Bassinettes",
      toddler_bed: "Lits pour bébé",
      hammock: "Hamacs",
      water_bed: "Lits d'eau",
      sofa_bed: "Divan-lits",
      murphy_bed: "Lits Escamotables",
      couch: "Divans",
      floor_mattress: "Matelas de sol"
    },
    propertyTypes: {
      home: "Maison",
      "b-and-b": "B & B",
      apartment: "Appartement ",
      cabin: "Cabine ",
      castle: "Château",
      cave: "Caverne",
      chalet: "Chalet",
      condo: "Condo",
      cottage: "Cottage",
      "country-home": "Maison de campagne",
      dorm: "Dortoir",
      "farm-home": "Maison de ferme",
      "guest-suite": "Suite d'invités",
      guesthouse: "Maison d'invités",
      hostel: "Auberge de jeunesse",
      hut: "Hutte",
      inn: "Auberge",
      island: "Îles",
      loft: "Loft",
      "nature-lodge": "Loge en Nature",
      other: "Autre",
      shelter: "Refuge",
      timeshare: "Timeshare",
      "tree-house": "Maison dans les arbres",
      "vacation-home": "Maison de vacances",
      villa: "Villa",
      "beach-house": "Maison de plage",
      boat: "Bateau",
      "boutique-hotel": "Hôtel boutique",
      camper: "Camper/RV",
      tent: "Tente",
      townhouse: "Maison de ville",
      yurt: "Yourte",
      hotel: "Hôtel"
    },
    rentTypes: {
      entire_home: "Maison Entière",
      private_room: "Chambre Privée",
      shared_room: "Chambre Partagée"
    },
    amenityGroups: {
      general: "Équipement général",
      family_features: "Équipement familial",
      outdoor: "Équipement extérieur",
      sports_adventures_nearby: "Sports et aventures à proximité",
      kitchen: "Cuisine",
      suitable_for_events: "Convient aux événements",
      accessibility: "Accessibilité",
      services: "Services"
    },
    amenityNames: {
      "air-conditioning": "Climatisation",
      "bed-linens": "Draps",
      "cable-tv": "Câble télé",
      dryer: "Sécheuse",
      "dvd-blue-ray": "DVD / Blue Ray",
      "carbon-monoxide-detector": "Détecteur de monoxyde de carbone",
      "smoke-detector": "Détecteur de fumée",
      "extra-pillows-and-blankets": "Literie extra",
      "firewood-included": "Bois de chauffage inclus",
      "hair-dryer": "Sèche-cheveux",
      "hangers-in-closets": "Cintres dans les placards",
      "heated-floors": "Planchers chauffants",
      heating: "Chauffage",
      "home-essentials": "Essentiels de maison",
      "hot-water": "Eau chaude",
      "indoor-fireplace": "Foyer intérieur",
      iron: "Fer à repasser",
      kitchen: "Cuisine",
      "computer-workspace": "Espace ordinateur",
      "potable-water": "Eau potable",
      "shampoo-soap": "Shampooing et savon",
      "toilet-paper": "Papier de toilette",
      towels: "Serviettes",
      tv: "Télé",
      washer: "Machine à laver",
      "wireless-internet": "Internet sans fil",
      "baby-bath": "Bain pour bébé",
      "baby-monitor": "Moniteur pour bébé",
      "babysitter-referrals": "Références pour gardienne",
      bathtub: "Baignoire",
      "changing-table": "Table à langer",
      "children-s-books-and-toys": "Livres et jouets pour enfants",
      "children-s-dinnerware": "Vaisselle pour enfants",
      crib: "Bassinette",
      "fireplace-guards": "Gardes pour foyer",
      "foosball-table": "Table de baby-foot",
      "game-console": "Console de jeu",
      "high-chair": "Chaise haute",
      "outlet-covers": "Prises électriques sécurisées",
      "ping-pong-table": "Table de ping pong",
      "pool-table": "Table de billard",
      "room-darkening-shades": "Rideaux dans les chambres",
      "stair-gates": "Gardes d'escalier",
      "table-corner-guards": "Protège-coins de table",
      "window-guards": "Garde-fenêtre",
      "coffee-maker": "Cafetière",
      "cooking-basics": "Épices de base pour cuisiner",
      "dishes-and-silverware": "Vaisselle et ustensiles",
      dishwasher: "Lave-vaisselle",
      microwave: "Micro-ondes",
      oven: "Four",
      "pots-and-pans": "Casseroles et poêles",
      refrigerator: "Réfrigérateur",
      stove: "Poêle",
      "access-to-water": "Accès à l'eau",
      "bbq-grill": "Barbecue",
      "beach-chairs": "Chaises de plage",
      beachfront: "Bord de mer",
      "by-a-river": "Bord de rivière",
      dock: "Quai",
      "fire-pit": "Feu extérieur",
      "garden-or-backyard": "Jardin ou cour arrière",
      hamak: "Hamac",
      "hot-tub-spa": "Jacuzzi / Spa",
      lakefront: "Bord de lac",
      "life-jackets": "Gilets de sauvetage",
      "patio-furniture": "Meubles de patio",
      "patio-or-balcony": "Patio ou balcon",
      pool: "Piscine",
      "sandy-beach": "Plage de sable",
      sauna: "Sauna",
      "ski-in-ski-out": "Ski in / ski out",
      "volleyball-net": "Filet de volleyball",
      "alpine-skiing": "Ski alpin",
      "atv-trail": "ATV trail",
      basketball: "Basketball",
      boating: "Bateau",
      parasailing: "Paravoile",
      "road-biking": "Vélo de route",
      "mountain-biking": "Vélo de montagne",
      kayaking: "Kayak",
      "jet-skiing": "Jet ski",
      "ice-climbing": "Escalade sur glace",
      "horseback-riding": "Équitation",
      hockey: "Hockey",
      hiking: "Randonnée",
      golf: "Golf",
      fishing: "Pêche",
      "deepsea-fishing": "Pêche en haute mer",
      "cross-country-skiing": "Ski de fond",
      caving: "Spéléologie",
      canoeing: "Canoë",
      "rock-climbing": "Escalade",
      rollerblading: "Patin à roues alignées",
      running: "Course",
      sailing: "Voile",
      "scuba-diving": "Plongée sous-marine",
      skating: "Patinage sur glace",
      skydiving: "Parachutisme",
      snorkeling: "Plongée en apnée",
      snowboarding: "Planche à neige",
      "snowmobile-trail": "Sentiers de motoneige",
      snowshoeing: "Sentiers de raquette",
      swimming: "Natation",
      windsurfing: "Planche à voile",
      tennis: "Tennis",
      "tree-climbing": "Escalade dans les arbres",
      volleyball: "Volley-ball",
      "water-skiing": "Ski nautique",
      "water-sliding": "Glissades d'eau",
      "whitewater-rafting": "Rafting",
      "elevator-in-the-building": "Ascenseur dans l'immeuble",
      "private-entrance": "Entrée privée",
      "single-level-home": "Maison avec un seul étage",
      "wheelchair-accessible": "Accessible aux fauteuils roulants",
      "breakfast-included": "Petit-déjeuner inclus",
      "ev-charger": "Chargeur électrique",
      "free-gym-fitness-centre": "Gym gratuit",
      "free-parking-on-premises": "Stationnement gratuit",
      "guided-tours": "Visites guidées",
      "maid-service-included": "Service de ménage inclus",
      "restaurant-on-site": "Restaurant sur place",
      "spa-services": "Services de Spa",
      "tea-coffee-included": "Thé et café inclus",
      "art-show": "Exposition d'art",
      "bachelor-ette-party": "Bachelor(ette) party",
      "birthday-party": "Fête d'anniversaire",
      conference: "Conférence",
      "corporate-meeting": "Réunion d'entreprise",
      "executive-retreat": "Séjours d'entrepreneurs",
      "friends-family-meeting": "Réunion d'amis et famille",
      "photo-film-shoot": "Tournages films / photos",
      "product-launch": "Lancement de produit",
      "seminar-networking": "Séminaire & Réseautage",
      "sport-team-training": "Entraînement sportif",
      wedding: "Mariage"
    },
    houseRules: {
      name: "Règles de la maison",
      allow_infants: "Les bébés de moins de 2 ans sont les bienvenus",
      not_allow_infants:
        "L'endroit ne convient pas aux bébés de moins de 2 ans",
      allow_children: "Les enfants sont les bienvenus (2 à 12 ans)",
      not_allow_children:
        "L'endroit n'est pas adapté aux enfants de 2 à 12 ans",
      allow_smoking: "Fumer à l'intérieur est autorisé",
      not_allow_smoking: "Fumer à l'intérieur est interdit",
      allow_pets: "Les animaux sont acceptés",
      not_allow_pets: "Les animaux sont interdits",
      allow_parties: "Les fêtes et événements sont autorisés",
      not_allow_parties: "Les fêtes et événements sont interdits"
    },
    guestExpectationsRules: {
      "must-climb-stairs": "Vous devez monter des escaliers",
      "potential-for-noise": "Potentiel de bruit",
      "pets-live-on-property": "Des animaux vivent dans cette propriété",
      "no-parking-on-property":
        "Il n'y a pas de stationnement à cette propriété",
      "some-spaces-are-shared": "Certains espaces sont partagés",
      "amenity-limitations": "Équipements limités",
      "cell-phone-signal-weak":
        "La réception du téléphone cellulaire est réduite près de cette propriété",
      "awd-vehicles-preferable":
        "L'accès avec un véhicule à quatre roues motrices est préférable en hiver",
      "water-safe-to-drink": "L'eau n'est pas potable"
    },
    actions: {
      save: "Sauvegarder",
      save_next: "Étape Suivante",
      next: "Next",
      cancel: "Annuler",
      publish: "Publier",
      pause: "Pauser",
      delete: "Supprimer",
      back: "Retour",
      viewListing: "Voir Annonce"
    },
    add_property: "Ajouter propriété",
    edit_property: "Éditer propriété",
    space: "Espace",
    location: "Localisation",
    amenities: "Équipements",
    photos: "Photos",
    description: "Description",
    booking_settings: "Paramètres de réservation",
    pricing: "Prix",
    calendar: "Calendrier",
    guest_material: "Matériel d'invités",
    status: "Statut",
    discount_limit: "Le rabais maximal est de 99%.",
    pricing_limit: "Veuillez mettre un maximum de 6 chiffres.",
    taxNames: {
      TPS: "TPS",
      TVH: "TVH",
      TVP: "TVP",
      TVQ: "TVQ",
      GST: "TPS",
      HST: "TVH",
      PST: "TVP",
      QST: "TVQ"
    },
    fraudText: "Nous détectons les fraudes",
    fraudTextLine2: "Votre identité sera vérifiée"
  }
};
