import { useState, useRef, useEffect, cloneElement } from "react";
import { TooltipPopup, useTooltip } from "@reach/tooltip";
import "@reach/tooltip/styles.css";
import { Portal } from "react-portal";
import { useTransition, animated } from "@react-spring/web";

const AnimatedTooltipContent = animated(TooltipPopup);

const TriangleTooltip = ({
  children,
  label,
  "aria-label": ariaLabel,
  isDark = true
}) => {
  const [trigger, tooltip] = useTooltip();
  const [isOpen, setIsOpen] = useState(false);
  const [isAbove, setIsAbove] = useState(false);
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const isMobileRef = useRef(false);

  const transitions = useTransition(tooltip.isVisible, {
    from: { opacity: 0, transform: "translateY(-10px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(-10px)" },
    config: { mass: 1, tension: 500, friction: 40 }
  });

  useEffect(() => {
    const checkMobile = () => {
      isMobileRef.current =
        "ontouchstart" in window || navigator.maxTouchPoints > 0;
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleToggle = (event) => {
    if (isMobileRef.current) {
      event.preventDefault();
      event.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  const updatePosition = () => {
    if (isOpen && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceBelow = viewportHeight - triggerRect.bottom;
      const spaceAbove = triggerRect.top;
      const tooltipHeight = tooltipRect.height;

      const shouldBeAbove =
        spaceBelow < tooltipHeight && spaceAbove > spaceBelow;
      setIsAbove(shouldBeAbove);

      const left = triggerRect.left + window.scrollX;
      let top;

      if (shouldBeAbove) {
        top = triggerRect.top + window.scrollY - tooltipHeight - 8;
      } else {
        top = triggerRect.bottom + window.scrollY + 8;
      }

      tooltipRef.current.style.left = `${left}px`;
      tooltipRef.current.style.top = `${top}px`;
    }
  };

  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isMobileRef.current) {
      updatePosition();
      window.addEventListener("resize", updatePosition);
      window.addEventListener("scroll", handleScroll);
      document.addEventListener("click", handleClickOutside);

      return () => {
        window.removeEventListener("resize", updatePosition);
        window.removeEventListener("scroll", handleScroll);
        document.removeEventListener("click", handleClickOutside);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const triggerProps = {
    ...trigger,
    ref: (node) => {
      triggerRef.current = node;
      if (trigger.ref) {
        trigger.ref(node);
      }
    },
    onClick: handleToggle
  };

  const renderMobileTooltip = () => (
    <Portal>
      <div
        ref={tooltipRef}
        aria-label={ariaLabel}
        style={{
          position: "absolute",
          zIndex: 99999999,
          background: isDark ? "#4f5253" : "#fff",
          color: isDark ? "white" : "#000",
          padding: "8px 12px",
          borderRadius: "4px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          maxWidth: "calc(100% - 20px)"
        }}
      >
        {label}
        <div
          style={{
            position: "absolute",
            [isAbove ? "bottom" : "top"]: -8,
            left: 16,
            width: 0,
            height: 0,
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            [isAbove ? "borderTop" : "borderBottom"]: `8px solid ${
              isDark ? "#4f5253" : "#fff"
            }`
          }}
        />
      </div>
    </Portal>
  );

  const renderDesktopTooltip = () =>
    transitions(
      (styles, item) =>
        item && (
          <AnimatedTooltipContent
            {...tooltip}
            label={label}
            aria-label={ariaLabel}
            style={{
              ...styles,
              background: isDark ? "#4f5253" : "#fff",
              color: isDark ? "white" : "#000",
              border: "none",
              borderRadius: "4px",
              padding: "8px 12px",
              zIndex: 99999999,
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)"
            }}
          >
            <div>
              {label}
              <div
                style={{
                  position: "absolute",
                  top: -8,
                  left: "50%",
                  marginLeft: -8,
                  width: 0,
                  height: 0,
                  borderLeft: "8px solid transparent",
                  borderRight: "8px solid transparent",
                  borderBottom: `8px solid ${isDark ? "#4f5253" : "#fff"}`
                }}
              />
            </div>
          </AnimatedTooltipContent>
        )
    );

  return (
    <>
      {cloneElement(children, triggerProps)}
      {isMobileRef.current
        ? isOpen && renderMobileTooltip()
        : renderDesktopTooltip()}
    </>
  );
};

export default TriangleTooltip;
