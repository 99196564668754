import { RouterRoutes } from "modules/router/types";
import { viewLoadable } from "modules/router/utils";

const InfoView = viewLoadable(() => import("pages/info"));
const HomeView = viewLoadable(() => import("pages/HomeView"));
const UsersView = viewLoadable(() => import("pages/users"));
const TermsView = viewLoadable(() => import("pages/terms"));
const SearchView = viewLoadable(() => import("pages/search"));
const ListingView = viewLoadable(() => import("pages/homes"));
const ContactView = viewLoadable(() => import("pages/contact"));
const BecomeHostView = viewLoadable(() => import("pages/become-host"));
const OurPartners = viewLoadable(() => import("pages/our-partners"));
const HostingGuideView = viewLoadable(() => import("pages/hosting-guide"));
const HubPage = viewLoadable(() => import("pages/hubs/hub"));

const EmailVerifyView = viewLoadable(() => import("pages/auth/EmailVerify"));
const ResetPasswordView = viewLoadable(
  () => import("pages/auth/ResetPassword")
);
const LoginWithTokenView = viewLoadable(
  () => import("pages/auth/LoginWithToken")
);
const AccountSuspendedView = viewLoadable(
  () => import("pages/auth/AccountSuspended")
);

const InboxView = viewLoadable(() => import("pages/dashboard/inbox"));
const InvoicesView = viewLoadable(() => import("pages/dashboard/invoices"));
const ProfileEditView = viewLoadable(
  () => import("pages/dashboard/profile/Edit")
);
const ProfilePaymentView = viewLoadable(
  () => import("pages/dashboard/profile/Payout")
);
const ProfilePasswordView = viewLoadable(
  () => import("pages/dashboard/profile/Password")
);
const IntegrationsView = viewLoadable(
  () => import("pages/dashboard/hosting/integrations")
);
const PricelabsIntegrationView = viewLoadable(
  () => import("pages/dashboard/hosting/integrations/pricelabs")
);
const HostawayIntegrationView = viewLoadable(
  () => import("pages/dashboard/hosting/integrations/hostaway")
);
const LodgifyIntegrationView = viewLoadable(
  () => import("pages/dashboard/hosting/integrations/lodgify")
);
const HostingView = viewLoadable(
  () => import("pages/dashboard/hosting/management")
);
const Calendars = viewLoadable(
  () => import("pages/dashboard/hosting/listing-calendar")
);
const CreateListingView = viewLoadable(
  () => import("pages/dashboard/listing/create")
);
const EditListingView = viewLoadable(() => import("pages/dashboard/listing"));

const FavoritesView = viewLoadable(() => import("pages/dashboard/favorites"));

const UpcomingTripsView = viewLoadable(() => import("pages/dashboard/trips/Upcoming"));
const PastTripsView = viewLoadable(() => import("pages/dashboard/trips/Past"));
const CancelledTripsView = viewLoadable(() => import("pages/dashboard/trips/Cancelled"));

const BookingView = viewLoadable(() => import("pages/dashboard/booking"));
const BookingFailedView = viewLoadable(() => import("pages/dashboard/booking/Failed"));

const AccountVerificationView = viewLoadable(
  () => import("pages/dashboard/account-verification")
);

const CheckoutReviewView = viewLoadable(() => import("pages/dashboard/checkout/Review"));
const CheckoutPaymentView = viewLoadable(() => import("pages/dashboard/checkout/Payment"));
const CheckoutThankYouView = viewLoadable(() => import("pages/dashboard/checkout/ThankYou"));
const CheckoutFailedView = viewLoadable(() => import("pages/dashboard/checkout/Failed"));

const routes: RouterRoutes = {
  home: {
    path: "/",
    mustBeAuthenticated: false,
    component: HomeView,
    activePaths: ["/search", "/homes/:listingId", "/users/:userId"]
  },
  terms: {
    path: "/terms",
    mustBeAuthenticated: false,
    component: TermsView,
    locales: {
      fr: "/termes"
    }
  },
  contactUs: {
    path: "/contact",
    mustBeAuthenticated: false,
    component: ContactView
  },
  becomeHost: {
    path: "/host",
    mustBeAuthenticated: false,
    component: BecomeHostView,
    locales: {
      fr: "/hote"
    }
  },
  ourPartners: {
    path: "/partners",
    mustBeAuthenticated: false,
    component: OurPartners,
    locales: {
      fr: "/partenaires"
    }
  },
  hostingGuide: {
    path: "/hosting-guide",
    mustBeAuthenticated: false,
    component: HostingGuideView,
    locales: {
      fr: "/guide-hebergement"
    }
  },
  hub: {
    path: "/hubs/:hubId+",
    mustBeAuthenticated: false,
    component: HubPage,
    locales: {
      fr: "/hubs/:hubId+"
    }
  },
  search: {
    path: "/search",
    mustBeAuthenticated: false,
    component: SearchView,
    locales: {
      fr: "/recherche"
    }
  },
  users: {
    path: "/users/:userId",
    mustBeAuthenticated: false,
    component: UsersView,
    locales: {
      fr: "/utilisateurs/:userId"
    }
  },
  homes: {
    path: "/homes/:listingId",
    mustBeAuthenticated: false,
    component: ListingView,
    locales: {
      fr: "/proprietes/:listingId"
    }
  },
  info: {
    path: "/info",
    mustBeAuthenticated: false,
    component: InfoView
  },

  // Auth related routes
  loginWithToken: {
    path: "/auth/login/authorize",
    mustBeAuthenticated: false,
    component: LoginWithTokenView,
    locales: {
      fr: "/auth/connexion/authorisation"
    }
  },
  verifyEmail: {
    path: "/auth/email/verify",
    mustBeAuthenticated: false,
    component: EmailVerifyView,
    locales: {
      fr: "/auth/courriel/verification"
    }
  },
  passwordReset: {
    path: "/auth/password/reset",
    mustBeAuthenticated: false,
    component: ResetPasswordView,
    locales: {
      fr: "/auth/mot-de-passe/reinitialiser"
    }
  },
  accountSuspended: {
    path: "/auth/account-suspended",
    mustBeAuthenticated: false,
    component: AccountSuspendedView,
    locales: {
      fr: "/auth/compte-suspendu"
    }
  },

  // Dashboard related routes
  dashboard: {
    path: "/dashboard",
    mustBeAuthenticated: true,
    component: ProfileEditView,
    locales: {
      fr: "/tableau-de-bord"
    }
  },
  inbox: {
    path: "/dashboard/inbox",
    mustBeAuthenticated: true,
    component: InboxView,
    locales: {
      fr: "/tableau-de-bord/message"
    }
  },
  inboxTravelling: {
    path: "/dashboard/inbox/travelling",
    mustBeAuthenticated: true,
    component: InboxView,
    activePaths: [
      "/dashboard/inbox/hosting"
    ],
    locales: {
      fr: "/tableau-de-bord/message/voyage"
    },
    data: {
      type: "travelling"
    }
  },
  inboxHosting: {
    path: "/dashboard/inbox/hosting",
    mustBeAuthenticated: true,
    component: InboxView,
    activePaths: [
      "/dashboard/inbox/travelling"
    ],
    locales: {
      fr: "/tableau-de-bord/message/hebergement"
    },
    data: {
      type: "hosting"
    }
  },
  invoices: {
    path: "/dashboard/invoices",
    mustBeAuthenticated: true,
    component: InvoicesView,
    locales: {
      fr: "/tableau-de-bord/factures"
    },
    redirect: "/dashboard/invoices/travelling"
  },
  transactionsTravelling: {
    path: "/dashboard/invoices/travelling",
    mustBeAuthenticated: true,
    component: InvoicesView,
    activePaths: [
      "/dashboard/invoices/hosting"
    ],
    locales: {
      fr: "/tableau-de-bord/factures/voyage"
    },
    data: {
      type: "travelling"
    }
  },
  transactionsHosting: {
    path: "/dashboard/invoices/hosting",
    mustBeAuthenticated: true,
    component: InvoicesView,
    activePaths: [
      "/dashboard/invoices/travelling"
    ],
    locales: {
      fr: "/tableau-de-bord/factures/hebergement"
    },
    data: {
      type: "hosting"
    }
  },
  profile: {
    path: "/dashboard/profile/edit-profile",
    mustBeAuthenticated: true,
    component: ProfileEditView,
    locales: {
      fr: "/tableau-de-bord/profil/modifier"
    }
  },
  payoutSettings: {
    path: "/dashboard/profile/payout-settings",
    mustBeAuthenticated: true,
    component: ProfilePaymentView,
    locales: {
      fr: "/tableau-de-bord/profil/paiement"
    }
  },
  changePassword: {
    path: "/dashboard/profile/change-password",
    mustBeAuthenticated: true,
    component: ProfilePasswordView,
    locales: {
      fr: "/tableau-de-bord/profil/mot-de-passe"
    }
  },
  hosting: {
    path: "/dashboard/hosting",
    mustBeAuthenticated: true,
    component: HostingView,
    locales: {
      fr: "/tableau-de-bord/hebergement"
    }
  },
  calendars: {
    path: "/dashboard/calendars",
    mustBeAuthenticated: true,
    component: Calendars,
    locales: {
      fr: "/tableau-de-bord/calendriers"
    }
  },
  createListing: {
    path: "/dashboard/hosting/add-listing",
    mustBeAuthenticated: true,
    component: CreateListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete"
    }
  },
  addListingLocation: {
    path: "/dashboard/hosting/add-listing/:listingId/location",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/localisation"
    },
    data: { page: "location" }
  },
  addListingSpace: {
    path: "/dashboard/hosting/add-listing/:listingId/space",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/espace"
    },
    data: { page: "space" }
  },
  addListingAmenities: {
    path: "/dashboard/hosting/add-listing/:listingId/amenities",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/equipement"
    },
    data: { page: "amenities" }
  },
  addListingPhotos: {
    path: "/dashboard/hosting/add-listing/:listingId/photos",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/photos"
    },
    data: { page: "photos" }
  },
  addListingDescription: {
    path: "/dashboard/hosting/add-listing/:listingId/description",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/description"
    },
    data: { page: "description" }
  },
  addListingBookingSettings: {
    path: "/dashboard/hosting/add-listing/:listingId/booking-settings",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/parametres-de-reservation"
    },
    data: { page: "booking-settings" }
  },
  addListingPricing: {
    path: "/dashboard/hosting/add-listing/:listingId/pricing",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/prix"
    },
    data: { page: "pricing" }
  },
  addListingCalendar: {
    path: "/dashboard/hosting/add-listing/:listingId/calendar",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/calendrier"
    },
    data: { page: "calendar" }
  },
  addListingGuestMaterial: {
    path: "/dashboard/hosting/add-listing/:listingId/guest-material",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/materiel-invites"
    },
    data: { page: "guest-material" }
  },
  addListingStatus: {
    path: "/dashboard/hosting/add-listing/:listingId/status",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/ajouter-propriete/:listingId/statut"
    },
    data: { page: "status" }
  },
  editListing: {
    path: "/dashboard/hosting/edit-listing/:listingId",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId"
    },
    data: { isEdit: true, page: "location" }
  },
  editListingLocation: {
    path: "/dashboard/hosting/edit-listing/:listingId/location",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/localisation"
    },
    data: { isEdit: true, page: "location" }
  },
  editListingSpace: {
    path: "/dashboard/hosting/edit-listing/:listingId/space",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/espace"
    },
    data: { isEdit: true, page: "space" }
  },
  editListingAmenities: {
    path: "/dashboard/hosting/edit-listing/:listingId/amenities",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/equipement"
    },
    data: { isEdit: true, page: "amenities" }
  },
  editListingPhotos: {
    path: "/dashboard/hosting/edit-listing/:listingId/photos",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/photos"
    },
    data: { isEdit: true, page: "photos" }
  },
  editListingDescription: {
    path: "/dashboard/hosting/edit-listing/:listingId/description",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/description"
    },
    data: { isEdit: true, page: "description" }
  },
  editListingBookingSettings: {
    path: "/dashboard/hosting/edit-listing/:listingId/booking-settings",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/parametres-de-reservation"
    },
    data: { isEdit: true, page: "booking-settings" }
  },
  editListingPricing: {
    path: "/dashboard/hosting/edit-listing/:listingId/pricing",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/prix"
    },
    data: { isEdit: true, page: "pricing" }
  },
  editListingCalendar: {
    path: "/dashboard/hosting/edit-listing/:listingId/calendar",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/calendrier"
    },
    data: { isEdit: true, page: "calendar" }
  },
  editListingGuestMaterial: {
    path: "/dashboard/hosting/edit-listing/:listingId/guest-material",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/materiel-invites"
    },
    data: { isEdit: true, page: "guest-material" }
  },
  editListingStatus: {
    path: "/dashboard/hosting/edit-listing/:listingId/status",
    mustBeAuthenticated: true,
    component: EditListingView,
    locales: {
      fr: "/tableau-de-bord/hebergement/modifier-propriete/:listingId/statut"
    },
    data: { isEdit: true, page: "status" }
  },
  integrations: {
    path: "/dashboard/hosting/integrations",
    mustBeAuthenticated: true,
    component: IntegrationsView,
    locales: {
      fr: "/tableau-de-bord/hebergement/integrations"
    }
  },
  integrationsPricelabs: {
    path: "/dashboard/hosting/integrations/pricelabs",
    mustBeAuthenticated: true,
    component: PricelabsIntegrationView,
    locales: {
      fr: "/tableau-de-bord/hebergement/integrations/pricelabs"
    }
  },
  integrationsHostaway: {
    path: "/dashboard/hosting/integrations/hostaway",
    mustBeAuthenticated: true,
    component: HostawayIntegrationView,
    locales: {
      fr: "/tableau-de-bord/hebergement/integrations/hostaway"
    }
  },
  integrationsLodgify: {
    path: "/dashboard/hosting/integrations/lodgify",
    mustBeAuthenticated: true,
    component: LodgifyIntegrationView,
    locales: {
      fr: "/tableau-de-bord/hebergement/integrations/lodgify"
    }
  },
  favorites: {
    path: "/dashboard/travelling/favorites",
    mustBeAuthenticated: true,
    component: FavoritesView,
    locales: {
      fr: "/tableau-de-bord/voyage/favoris"
    }
  },
  travelling: {
    path: "/dashboard/trips",
    mustBeAuthenticated: true,
    component: UpcomingTripsView,
    locales: {
      fr: "/tableau-de-bord/voyages"
    }
  },
  travellingUpcoming: {
    path: "/dashboard/trips/upcoming",
    mustBeAuthenticated: true,
    component: UpcomingTripsView,
    activePaths: [
      "/dashboard/trips"
    ],
    locales: {
      fr: "/tableau-de-bord/voyages/a-venir"
    }
  },
  travellingPast: {
    path: "/dashboard/trips/past",
    mustBeAuthenticated: true,
    component: PastTripsView,
    locales: {
      fr: "/tableau-de-bord/voyages/precedents"
    }
  },
  travellingCancelled: {
    path: "/dashboard/trips/cancelled",
    mustBeAuthenticated: true,
    component: CancelledTripsView,
    locales: {
      fr: "/tableau-de-bord/voyages/annules"
    }
  },
  booking: {
    path: "/dashboard/booking/:bookingId",
    mustBeAuthenticated: true,
    component: BookingView,
    locales: {
      fr: "/tableau-de-bord/reservation/:bookingId"
    }
  },
  failedBooking: {
    path: "/dashboard/failed-booking",
    mustBeAuthenticated: true,
    component: BookingFailedView,
    locales: {
      fr: "/tableau-de-bord/echec-reservation"
    }
  },
  accountVerification: {
    path: "/dashboard/account-verification",
    mustBeAuthenticated: true,
    component: AccountVerificationView,
    locales: {
      fr: "/tableau-de-bord/verification-compte"
    }
  },
  checkout: {
    path: "/checkout/:bookingId",
    mustBeAuthenticated: true,
    component: CheckoutReviewView,
    locales: {
      fr: "/caisse/:bookingId"
    }
  },
  checkoutReview: {
    path: "/checkout/:bookingId/review",
    mustBeAuthenticated: true,
    component: CheckoutReviewView,
    locales: {
      fr: "/caisse/:bookingId/reviser"
    }
  },
  checkoutPayment: {
    path: "/checkout/:bookingId/payment",
    mustBeAuthenticated: true,
    component: CheckoutPaymentView,
    locales: {
      fr: "/caisse/:bookingId/paiement"
    }
  },
  checkoutThankyou: {
    path: "/checkout/:bookingId/thank-you",
    mustBeAuthenticated: true,
    component: CheckoutThankYouView,
    locales: {
      fr: "/caisse/:bookingId/merci"
    }
  },
  checkoutFailed: {
    path: "/checkout/:bookingId/sorry",
    mustBeAuthenticated: true,
    component: CheckoutFailedView,
    locales: {
      fr: "/caisse/:bookingId/desole"
    }
  }
};

export default routes;
