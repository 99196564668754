import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import { Tooltip } from "react-tooltip";

// Some of the markup depends on the design library styles
// TODO: Remove this once the CSS refactor is over to improve bundle size
import "@design-library";

import configureStore from "./redux/store";
import { HubspotProvider } from "./hubspot";
import { SegmentProvider } from "./segment";
import Meta from "./Meta";
import * as serviceWorker from "./serviceWorker";

import "./styles/index.scss";
import "./dayjs-config";
import CookieConsentProvider from "modules/layout/containers/cookie-consent-provider";
import { ParallaxProvider } from "react-scroll-parallax";
import { AnalyticsProvider } from "modules/layout";

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID
});

// Create redux store with history
export const store = configureStore({});

// Loader application routes wrapped in react-dom-router
const loadRouter = async () => {
  const module = await import("./modules/router");
  return module.default;
};

const loadCurrencyProvider = async () => {
  const module = await import("./modules/currency");
  return module.default;
};

const loadErrorBoundary = async () => {
  const module = await import("./components/error-boundary");
  return module.default;
};

const MOUNT_NODE = document.getElementById("root");

/**
 * A custom store aware render function
 */
const render = async () => {
  const Router = await loadRouter();
  const CurrencyProvider = await loadCurrencyProvider();
  const ErrorBoundary = await loadErrorBoundary();

  const Root = () => (
    <Provider store={store}>
      <Meta />
      <BrowserRouter>
        <ErrorBoundary>
          <CookieConsentProvider>
            <CurrencyProvider>
              <HubspotProvider enableOnLoad={true}>
                <AnalyticsProvider>
                  <SegmentProvider>
                    <ParallaxProvider>
                      <Router />
                    </ParallaxProvider>
                  </SegmentProvider>
                </AnalyticsProvider>
              </HubspotProvider>
            </CurrencyProvider>
          </CookieConsentProvider>
          <Tooltip place="bottom" style={{ zIndex: 999999999 }} id="select-tooltip" />
        </ErrorBoundary>
        {process.env.NODE_ENV !== "production" && !window.Cypress && (
          <ReactQueryDevtools />
        )}
      </BrowserRouter>
    </Provider>
  );

  if (MOUNT_NODE?.hasChildNodes()) {
    ReactDOM.hydrate(<Root />, MOUNT_NODE);
  } else {
    ReactDOM.render(<Root />, MOUNT_NODE);
  }
};

if (module.hot) {
  // Hot reloadable React components
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./routes", "./modules/router"], () => {
    if (MOUNT_NODE) {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    }
    render();
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// if (process.env.NODE_ENV === "production") {
//   serviceWorker.register();
// }

// Give Cypress access to app systems
if (window.Cypress) {
  window.__store__ = store;
  window.__Swal__ = require("sweetalert2");
}
