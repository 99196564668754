import { CookieMap } from "modules/layout/store/types";
import { FC } from "react";
import { getLocale } from "modules/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import acceptAllCookies from "modules/layout/store/actions/accept-all-cookies";
import acceptCustomCookies from "modules/layout/store/actions/accept-custom-cookies";
import Button from "@design-library/Button";
import classes from "./cookie-banner.module.scss";
import Query from "utils/Query";
import strings from "./messages";
import useLang from "@design-library/utils/useLang";
import selectIsAuthenticated from "modules/auth/selectors/select-is-authenticated";

interface Props {}

const CookieBanner: FC<Props> = () => {
  const locale = useSelector(getLocale);

  const lang = useLang(strings, locale);

  const dispatch = useDispatch();

  const [submit, { isLoading }] = useMutation(
    async (enable_cookies?: boolean) => {
      const response = await Query("v1/profile/set-cookies-preferences").put({
        enable_cookies
      });
      return response?.data;
    }
  );

  const onAcceptNecessary = async () => {
    if (isAuthenticated) {
      await submit(false);
    }

    const customCookies: CookieMap = {
      marketing: false
    };

    dispatch(acceptCustomCookies(customCookies));
  };

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const onAcceptAll = async () => {
    if (isAuthenticated) {
      await submit(true);
    }
    dispatch(acceptAllCookies());
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.discription}>{lang.description}</p>
      <div className={classes.buttons}>
        <Button
          className={classes.link}
          theme="link"
          onClick={onAcceptNecessary}
          disabled={isLoading}
        >
          {lang.acceptOnlyNecessary}
        </Button>
        <Button disabled={isLoading} onClick={onAcceptAll}>
          {lang.accept}
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
