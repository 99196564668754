import fetch from "cross-fetch";
import Qs, { Stringifiable } from "query-string";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";

const API_URL = process.env.REACT_APP_API_URL;

type Params = Record<
  string,
  string | number | boolean | Stringifiable[] | null | undefined
>;

const Query = (
  endpoint: string,
  signal?: AbortSignal,
  url = new URL(`${API_URL}/${endpoint}`)
) => {
  const options: RequestInit = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language":
        localStorage.getItem("locale") || document.documentElement.lang || "en",
      Accept: "application/json"
    },
    method: "GET",
    signal
  };

  const get = async (
    params: object = {},
    { headers = {} }: RequestInit = {}
  ) => {
    url.search = Qs.stringify(params as Params, { arrayFormat: "bracket" });
    options.headers = { ...options.headers, ...headers };
    return fetch(url.toString(), options)
      .then(handleUnauthenticated)
      .then(handleJsonResponse);
  };

  const remove = async (
    params: object = {},
    { headers = {} }: RequestInit = {}
  ) => {
    url.search = Qs.stringify(params as Params, { arrayFormat: "bracket" });
    options.method = "DELETE";
    options.headers = { ...options.headers, ...headers };
    return fetch(url.toString(), options)
      .then(handleUnauthenticated)
      .then(handleJsonResponse);
  };

  const post = async (
    params: object = {},
    { headers = {} }: RequestInit = {}
  ) => {
    options.method = "POST";
    options.body = JSON.stringify(params as Params);
    options.headers = { ...options.headers, ...headers };
    return fetch(url.toString(), options)
      .then(handleUnauthenticated)
      .then(handleJsonResponse);
  };

  const update = async (
    params: object = {},
    { headers = {} }: RequestInit = {}
  ) => {
    options.method = "PUT";
    options.body = JSON.stringify(params as Params);
    options.headers = { ...options.headers, ...headers };
    return fetch(url.toString(), options)
      .then(handleUnauthenticated)
      .then(handleJsonResponse);
  };

  const put = async (
    params: object = {},
    { headers = {} }: RequestInit = {}
  ) => {
    options.method = "PUT";
    options.body = JSON.stringify(params as Params);
    options.headers = { ...options.headers, ...headers };
    return fetch(url.toString(), options)
      .then(handleUnauthenticated)
      .then(handleJsonResponse);
  };

  const download = async (params: object = {}) => {
    url.search = Qs.stringify(params as Params, { arrayFormat: "bracket" });
    return fetch(url.toString(), {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "plain/text"
      }
    }).then(handleBlobResponse);
  };

  const upload = async (formData: FormData) => {
    return fetch(url.toString(), {
      credentials: "include",
      method: "POST",
      body: formData
    })
      .then(handleUnauthenticated)
      .then(handleJsonResponse);
  };

  const handleUnauthenticated = async (
    response: Response
  ): Promise<Response> => {
    // if backend is returning 401 status code, and localStorage
    // still persists a user instance, we remove user and reload window
    if (response.status === 401 && localStorage.getItem("user") !== null) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    return response;
  };

  const handleJsonResponse = async (response: Response) => {
    if (response.ok || response.status === 301) {
      // added this check, for status 204, when there is no content.
      return response.status === 204 ? {} : response.json();
    } else if (
      response.status === 500 &&
      process.env.NODE_ENV === "production"
    ) {
      window.location.href = "/500";
    } else if (
      response.status === 503 &&
      process.env.NODE_ENV === "production"
    ) {
      window.location.href = "/503";
    } else if (response.status === 422) {
      const error = {
        status: response.status
      };
      const { errors, message } = await response.json();
      (error as any).validations = errors;
      (error as any).message = message;
      return Promise.reject(error);
    } else {
      let error: any = {
        status: response.status
      };
      try {
        const responseJson = await response.json();
        error = {
          // @ts-expect-error
          message: response?.message,
          ...responseJson,
          ...error
        };
      } catch (error) {}

      return Promise.reject(error);
    }
  };

  const handleBlobResponse = async (response: Response) => {
    if (response.ok) {
      return response.blob();
    } else if (
      response.status === 500 &&
      process.env.NODE_ENV === "production"
    ) {
      window.location.href = "/500";
    } else if (
      response.status === 503 &&
      process.env.NODE_ENV === "production"
    ) {
      window.location.href = "/503";
    } else if (response.status === 422) {
      const error = {
        status: response.status,
        message: response.statusText
      };
      const { errors } = await response.json();
      (error as any).validations = errors;
      return Promise.reject(error);
    } else {
      const error = {
        status: response.status,
        message: response.statusText
      };
      return Promise.reject(error);
    }
  };

  return {
    get,
    post,
    update,
    put,
    delete: remove,
    download,
    upload
  };
};

export default Query;
